import React from "react";
//import { Link } from "gatsby";

import Layout from "../components/layout";
// import Image from "../components/image";
import SEO from "../components/seo";

// import { makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Grid container spacing={1} style={{flex: `grow`}}>
      {/* New Row */}
      <Grid item xs={1} sm={4} md={5} />
      <Grid item xs={10} sm={7} md={6} >
        <div id="mainHolder">
          <Paper id="mainBlurb" elevation={9}>
            <Typography style={{textAlign: `justify`, fontFamily: `'Shadows Into Light Two', Roboto, Helvetica, Arial, sans-serif`}}>
              <p>
              Solytica provides local- and cloud-based systems administration,
              DevOps support, website development, and consulting services. We carefully limit the number of clients with whom we
              are working at any time to ensure that every client receives nothing less than the utmost personal attention and
              service they deserve.
              </p>
              <Hidden smDown>
              <p style={{marginBottom: `calc(1.45rem / 2)`}}>
                While we can provide value across a wide range of needs, our core areas of expertise include:
              </p>
              <ul>
                <li>
                  Design, setup, and administration of local and cloud computing infrastructure
                  on Amazon AWS and Microsoft Azure. 
                </li>
                <li>
                  Database design and development, including relational databases 
                  (MySQL, MariaDB, PostgreSQL, and SQL Server, etc.) and unstructured
                  databases (DynamoDB, Cosmos DB, and key-value stores). 
                </li>
                <li>
                  Data analytics, business intelligence, and report development ranging from graphs in
                  spreadsheets to complex, online dashboards and reports built from a variety of online
                  and offline sources. We also generate infographics to assist in quickly understanding
                  what the data is telling you.
                </li>
                <li>
                  Website design and development ranging from static "brochure" websites to complex, data-driven
                  applications. We use the latest tools and frameworks, and we take the time to ensure your site 
                  meets the latest standards.
                </li>
              </ul>
              </Hidden>
            </Typography>
          </Paper>
        </div>
      </Grid>
      <Grid item xs={1} />
      {/* New Row */}
      <Grid item xs={1} sm={4} md={5} />
      <Grid item xs={10} sm={7} md={6} style={{marginRight: `-5vw`}}>
        <div id="contactHolder">
          <Paper id="contactBlurb" elevation={9}>
            <Typography style={{textAlign: `center`}}>eMail us to learn more <br />
            <a href="mailto:info@solytica.com">info@solytica.com</a></Typography>
          </Paper>
        </div>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  </Layout>
);

export default IndexPage;
